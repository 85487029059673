<template>
  <b-modal
    id="custom-modal-height"
    visible
    centered
    title="Редактирование сообщения"
    no-close-on-backdrop
  >
    <b-form-textarea
      id="textarea"
      v-model="text"
      rows="3"
      max-rows="6"
      class="resizable-textarea my-3"
    />

    <template #modal-footer>
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        :disabled="text.length < 1"
        @click="editMessage"
      >
        Изменить
      </b-button>
    </template>
  </b-modal>
</template>

<script>

export default {
  name: 'MessageEditModal',
  props: {
    message: {
      type: Object,
      default: null,
    },
    appealId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      document: null,
      text: '',
    };
  },
  mounted() {
    this.text = this.message.text;
  },
  methods: {
    onClose() {
      this.$emit('input', false);
    },
    onInput(e) {
      this.$emit('inputText', e.target.innerText);
    },
    editMessage() {
      try {
        this.$emit('editSendMessage', this.text);
        this.onClose();
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.resizable-textarea {
  width: 100%;
  font-size: 13px;
  color: #2c2c2c;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  max-height: 500px;
  min-height: 40px;
  padding: 0 5px;
  align-content: center;
  max-width: 100%;
  outline: none !important;
  resize: none;
}

.resizable-textarea {
  scrollbar-color: #9ab6ea;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 17px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 17px;
    background: #e4f3ff;
  }
  &::-webkit-scrollbar-thumb {
    background: #9ab6ea;
    border-radius: 17px;
  }
}

::v-deep(#custom-modal-height .modal-content) {
  height: 300px;
}

::v-deep(#custom-modal-height .modal-body) {
  padding: 10px 17px;
}
</style>
